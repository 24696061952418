.ant-drawer-title {
  text-align: center;
  img {
    width: 80px;
  }
}
.drawerContent {
  div {
    font-size: 16px;
    margin-bottom: 10px;
    color: #7f7f7f;
  }
}
.headerMain {
  display: flex;
  justify-content: space-between;
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 60px;
  padding-right: 60px;
  @media screen and (max-width: 600px) {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    flex-direction: column;
  }
  .headerLeftSide {
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      display: none;
    }
    div {
      display: flex;
      align-items: center;
      margin-right: 30px;
      font-weight: normal;
      color: #a5a5a5;
      font-size: 14px;
    }
  }
  .headerLeftSideSearch {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
      width: 100%;
    }
    .headerSearchInput {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #a5a5a593;
      width: 95%;
      height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      cursor: text;
      input {
        width: 88%;
        height: 80%;
        border: none;
        outline: none;
        padding-left: 30px;
      }
      img {
        width: 20px;
        position: absolute;
      }
      svg {
        cursor: pointer;
        color: #b7b7b7;
        font-size: 18px;
      }
      .dropDown {
        position: absolute;
        width: calc(100% + 2px);
        top: 35px;
        background-color: white;
        border: 1px solid #a5a5a593;
        left: -1px;
        border-top: 0;
        padding: 10px;
        z-index: 999;
        .dropDownItem {
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 10px;
          cursor: pointer;
          &:hover {
            background-color: rgba(0, 0, 0, 0.11);
          }
        }
      }
    }
    .threeDots {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: max-content;
      .one,
      .two,
      .three {
        width: 5px;
        height: 7px;
      }
      .one {
        background-color: #1faab2;
      }
      .two {
        background-color: #f2a000;
        margin-top: 7px;
      }
      .three {
        background-color: #37d665;
        margin-top: 7px;
      }
    }
  }
  .headerRightSide {
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      width: 100%;
      justify-content: space-between;
    }
    button {
      width: 150px;
      height: 44px;
      outline: none;
      border: none;
      background-color: #2aafb4;
      color: white;
      font-weight: normal;
      cursor: pointer;
      @media screen and (max-width: 1280px) {
        width: 110px;
      }
      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 30px;
      font-weight: normal;
      color: #a5a5a5;
      font-size: 18px;
      @media screen and (max-width: 1280px) {
        margin-left: 15px;
      }
      @media screen and (max-width: 600px) {
        margin: 0;
      }
      .icon {
        color: #7f7f7f;
        font-size: 30px;
      }
      img {
        width: 30px;
      }
    }
  }
}

.searchListMain {
  height: 100%;
  padding: 0px 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 60px;
  @media screen and (max-width: 600px) {
    padding: 20px;
    padding-top: 0;
    padding-bottom: 50px;
  }
  .badgeDiv {
    width: 100%;
    button {
      width: 120px;
      background-color: #acacac;
      border: none;
      height: 33px;
      border-radius: 33px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      img {
        width: 60%;
      }
      svg {
        color: white;
      }
    }
  }
  .resultListings {
    width: 65%;
    margin-top: 40px;
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-top: 20px;
    }
    .head {
      border-bottom: 1px solid #acacac;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      h3 {
        font-size: 13px;
        margin: 0;
        font-weight: bolder;
        color: #4f4f4f;
      }
      span {
        font-size: 12px;
        margin: 0;
        font-weight: 600;
        color: #bdbdbd;
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 5px;
      position: relative;
      svg {
        position: absolute;
        right: -10px;
        color: #787878;
        font-size: 24px;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          right: 0;
          font-size: 20px;
        }
      }
      a {
        font-size: 20px;
        line-height: 24px;
        font-weight: normal;
        margin: 0;
        color: #3845b2;
        width: 80%;
        text-decoration: none;
        cursor: pointer;
        font-size: 16px;
        line-height: 26px;
        color: #2f3baf;
        font-weight: bold;
        font-family: "Arial";
        overflow-wrap: break-word;
        &:visited {
          color: #551a8b;
        }
        @media screen and (max-width: 600px) {
          width: 90%;
        }
      }
      .span1 {
        margin: 0;
        font-size: 16px;
        color: #4c9438;
        // margin-top: 10px;
        font-weight: normal;
        line-height: 24px;
        width: 80%;
        padding: 1px 0;
        font-size: 16px;
        line-height: 26px;
        color: #318716;
        font-family: "Arial";
        overflow-wrap: break-word;
        @media screen and (max-width: 600px) {
          width: 90%;
        }
      }
      .span2 {
        margin: 0;
        font-size: 13px;
        color: #a7a7a7;
        // margin-top: 7px;
        font-weight: normal;
        width: 80%;
        overflow-wrap: break-word;
        a {
          font-size: 13px;
          font-weight: normal;
        }
        @media screen and (max-width: 600px) {
          width: 90%;
        }
      }
      p {
        margin: 0;
        font-size: 13px;
        font-weight: normal;
        color: #777777;
        // margin-top: 10px;
        width: 80%;
        line-height: 20px;
        font-size: 16px;
        line-height: 26px;
        color: #484848;
        font-family: "Arial";
        overflow-wrap: break-word;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
  .addsDiv {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    button {
      border: 2px solid #2aafb4;
      background-color: white;
      color: #2aafb4;
      height: 48px;
      font-weight: bold;
      cursor: pointer;
      font-size: 16px;
      width: 100%;
    }
    .add1 {
      margin-top: 30px;
      p {
        color: #6b6b6b;
        margin: 0;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        a {
          color: #6b6b6b;
          margin: 0;
          font-size: 16px;
          font-weight: normal;
          text-align: center;
        }
      }
      img {
        width: 100%;
      }
    }
    .add2 {
      margin-top: 30px;
      .colorFullLines {
        display: flex;
        width: 100%;
        div {
          width: 20%;
          height: 3px;
          &.one {
            background-color: #f90d07;
          }
          &.two {
            background-color: #9f036e;
          }
          &.three {
            background-color: #f4a701;
          }
          &.four {
            background-color: #4eda75;
          }
          &.five {
            background-color: #2aafb4;
          }
        }
      }
      .add {
        padding: 25px;
        height: auto;
        filter: drop-shadow(2px 3.464px 5px rgba(2, 2, 2, 0.15));
        background-color: #ffffff;
        border: 1px solid #cdcdcd;
        .firstDiv {
          display: flex;
          justify-content: space-between;
          .greenDot {
            height: 8px;
            width: 8px;
            background-color: #5eed8a;
            border-radius: 50px;
          }
          .threeDots {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            .one,
            .two,
            .three {
              width: 5px;
              height: 6px;
            }
            .one {
              background-color: #1faab2;
            }
            .two {
              background-color: #f2a000;
              margin-top: 2px;
            }
            .three {
              background-color: #37d665;
              margin-top: 2px;
            }
          }
        }
        .img {
          width: 50%;
          margin-top: 10px;
        }
        p {
          margin: 0;
          font-size: 16px;
          color: #484848;
          font-weight: normal;
          line-height: 18px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .buttonDiv {
          button {
            border: none;
            color: white;
            background-color: #b2b2b2;
            width: 159px;
            height: 32px;
            width: 35%;
            border-radius: 50px;
            font-size: 16px;
            font-weight: normal;
            @media screen and (max-width: 600px) {
              width: 40%;
            }
          }
          button + button {
            margin-left: 20px;
          }
        }
        h3 {
          font-size: 20px;
          margin: 0;
          font-weight: bold;
          font-size: 36px;
          color: #000000;
        }
        img {
          color: #b1b1b1;
          margin-top: 35px;
          font-size: 20px;
        }
      }
      &.add3 {
        .add {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            margin-top: 0;
          }
          svg {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

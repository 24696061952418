.TermsMain {
  padding: 0 7px;
  padding-bottom: 50px;
  max-width: 40em;
  margin: auto;
  font-size: 100%;
  p,
  ul,
  li {
    font-size: 14px;
  }
  @media screen and (max-width: 850px) {
    padding-bottom: 50px;
    margin: auto;
  }
  @media screen and (max-width: 600px) {
    padding-bottom: 50px;
    margin: auto;
  }
}

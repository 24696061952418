.homeMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(70vh + 7px);
  @media screen and (max-width: 600px) {
    height: auto;
  }
  .logoImage {
    @media screen and (max-width: 600px) {
      text-align: center;
    }
    img {
      width: 440px;
      @media screen and (max-width: 600px) {
        width: 80%;
      }
    }
  }
  .launchingSoon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    h2 {
      margin: 0;
      opacity: 0.7;
      @media screen and (max-width: 600px) {
        font-size: 18px;
        margin-top: 10px;
      }
    }
    p {
      margin: 0;
      text-align: center;
      opacity: 0.7;
      font-size: 14px;
      @media screen and (max-width: 600px) {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
        margin-top: 5px;
        br {
          display: none;
        }
      }
    }
  }
  .inputDiv {
    width: 650px;
    border: 1px solid #b7b7b78f;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    box-shadow: 0px 7px 10px -3px #b7b7b78f;
    font-weight: normal !important;
    position: relative;
    @media screen and (max-width: 850px) {
      width: 580px;
    }
    @media screen and (max-width: 600px) {
      width: 80%;
    }
    svg {
      color: #b7b7b7;
      font-size: 20px;
      float: right;
      cursor: pointer;
      z-index: 999;
      margin-right: 20px;
    }
    input {
      width: 80%;
      height: 80%;
      border: none;
      font-size: 18px;
      z-index: 999;
      outline: none;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 15px;
      padding-top: 15px;
      border-radius: 30px;
      border-top: 1px solid #b7b7b78f;
      border-top-right-radius: 0;
      margin-top: -1px;
      &::placeholder {
        color: #b7b7b785;
        font-weight: normal;
      }
    }
    .hr {
      width: 100%;
      height: 2px;
      margin: 0;
      padding-top: 5px;
      padding-bottom: 5px;
      z-index: 999;
      border: none;
      margin-top: -10px;
      div {
        background-color: #a5a5a55e;
        height: 2px;
      }
    }
    .dropDown {
      position: absolute;
      left: -1px;
      top: 27px;
      border: 1px solid #b7b7b78f;
      background: white;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-top: 0;
      width: 650px;
      padding: 10px;
      padding-top: 25px;
      // max-height: 200px;
      // overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 30px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 30px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(136, 136, 136, 0.404);
        border-radius: 30px;
      }
      .dropDownItem {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.11);
        }
      }
    }
  }
  .afterInputText {
    margin-top: 30px;
    p {
      margin: 0;
      display: flex;
      align-items: center;
      margin-right: 30px;
      font-weight: normal;
      color: #a5a5a5;
      font-size: 16px;
    }
  }
}

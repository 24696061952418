@font-face {
  font-family: Roboto-regular;
  src: url("./assets/fonts/roboto/Roboto-Regular.ttf");
}

html {
  font-size: 100%;
}

button {
  font-family: Roboto-regular !important;
}
input {
  font-family: Roboto-regular !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto-regular !important;
  font-size: 1rem;
  line-height: 1.5;
}
p {
  font-family: Roboto-regular !important;
}

button:disabled {
  opacity: 0.5;
}

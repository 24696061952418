.footerMain {
  .colorFullLines {
    display: flex;
    width: 100%;
    div {
      width: 20%;
      height: 5px;
      &.one {
        background-color: #f90d07;
      }
      &.two {
        background-color: #9f036e;
      }
      &.three {
        background-color: #f4a701;
      }
      &.four {
        background-color: #4eda75;
      }
      &.five {
        background-color: #2aafb4;
      }
    }
  }
  .footerContent {
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .left {
      display: flex;
      align-items: center;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      img {
        width: 120px;
        margin-right: 30px;
        @media screen and (max-width: 600px) {
          margin: 0;
        }
      }
      div {
        font-size: 16px;
        color: #484848;
        font-weight: 600;
        margin-right: 15px;
        margin-left: 15px;
        a {
          color: #484848;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
    .right {
      div {
        font-size: 16px;
        color: #484848;
        font-weight: 600;
        padding-right: 15px;
        @media screen and (max-width: 600px) {
          font-size: 16px;
          text-align: center;
          margin-top: 20px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

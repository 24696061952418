.contactMain {
    padding: 0px 100px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 79vh;
    text-align: center;
    @media screen and (max-width: 850px) {
      padding: 0px 50px;
      padding-bottom: 50px;
    }
    @media screen and (max-width: 600px) {
      padding: 0px 20px;
      padding-bottom: 50px;
    }
  }
  